.ProductCard {
  height: 100%;
}

/* Hide the scrollbar handle */
.ProductCard *::-webkit-scrollbar-thumb {
  display: none;
}

.ProductCard * {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Set the width of the scrollbar track */
.ProductCard *::-webkit-scrollbar {
  width: 0;
  display: none;
}

/* Set the color of the scrollbar track */
.ProductCard *::-webkit-scrollbar-track {
  background-color: transparent;
}
